<template>
  <div class="main">
    <div class="title">新闻管理</div>
    <!-- 查询 -->
    <div class="search">
      <el-form
        :inline="true"
        :model="formData"
        class="demo-form-inline"
        label-width="90px"
      >
        <el-form-item label="新闻标题：">
          <el-input v-model="formData.title"></el-input>
        </el-form-item>
        <!-- <el-form-item label="新闻简介：">
          <el-input v-model="formData.intro"></el-input>
        </el-form-item> -->
        <el-form-item label="新闻状态：">
          <el-select v-model="formData.status" clearable placeholder="请选择">
            <el-option
              v-for="item in statusArr"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="新闻类型：">
          <el-select v-model="formData.type" clearable placeholder="请选择">
            <el-option
              v-for="item in statusType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-button type="primary" style="height: 40px" @click="onSubmit"
        >查询</el-button
      >
    </div>
    <!-- 表格部分 -->
    <div class="table">
      <div class="table-header">
        <p class="table-header-title">新闻管理列表</p>
        <el-button type="primary" @click="addDevice">新增新闻</el-button>
      </div>
      <el-table
        :data="tableData"
        border
        :header-cell-style="{ color: 'rgba(0, 0, 0, 0.85)' }"
        style="width: 100%; color: rgba(0, 0, 0, 0.85)"
      >
        <el-table-column prop="id" label="id" width="50" align="center">
        </el-table-column>
        <el-table-column prop="title" label="新闻标题" align="center">
        </el-table-column>
        <el-table-column
          label="新闻简介"
          show-overflow-tooltip
          align="center"
          width="200"
          prop="intro"
        >
        </el-table-column>
        <el-table-column
          prop="content"
          label="新闻内容"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column label="新闻状态" align="center">
          <span slot-scope="scope">
            {{ scope.row.status === 0 ? "启用" : "禁用" }}
          </span>
        </el-table-column>
        <el-table-column label="新闻类型" align="center">
          <span slot-scope="scope">
            {{ scope.row.type === 1 ? "公司新闻" : "行业新闻" }}
          </span>
        </el-table-column>
        <el-table-column prop="action" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="handleEdit(scope.$index, scope.row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="paging">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="10"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalPage"
          style="
            background-color: #fff;
            text-align: right;
            padding: 0px !important;
          "
        >
        </el-pagination>
      </div>
    </div>
    <!-- 新增/编辑新闻弹框 -->
    <el-dialog
      :title="isEdit ? '编辑新闻' : '新增新闻'"
      :visible.sync="dialogFormVisible"
      :before-close="closeExpertFormDialog"
      width="720px"
    >
      <el-form :model="form" label-width="100px" ref="form">
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="新闻标题：" >
              <el-input v-model="form.title" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
          <el-form-item label="新闻简介：">
            <el-input v-model="form.intro" autocomplete="off"></el-input>
          </el-form-item>
        <el-form-item label="新闻封面图片：">
          <el-upload
            class="avatar-uploader"
            action="https://api.zjsk2023.com/official-admin/base/aly/oss/upload"
            :on-success="handleAvatarSuccess"
            :show-file-list="false"
            :limit="1"
          >
            <img v-if="form.coverImage" :src="form.coverImage" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="新闻内容：">
          <el-input type="textarea" v-model="form.content" autocomplete="off">
          </el-input>
        </el-form-item>
        <el-form-item label="新闻状态：">
          <el-select v-model="form.status" clearable placeholder="请选择">
            <el-option
              v-for="item in statusArr"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="新闻类型：">
          <el-select v-model="form.type" clearable placeholder="请选择">
            <el-option
              v-for="item in statusType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="handleDialogCancel()">取 消</el-button>
        <el-button type="primary" @click="handleDialogConfirm()"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { pcaTextArr } from "element-china-area-data";
export default {
  inject: ["reload"],
  data() {
    return {
      formData: {
        title: "",
        intro: "",
        status: "",
        type: "",
      },
      statusArr: [
        { value: 0, label: "启用" },
        { value: 1, label: "禁用" },
      ],
      statusType: [
        { value: 1, label: "公司新闻" },
        { value: 2, label: "行业新闻" },
      ],
      currentPage: 1,
      pageSize: 10,
      totalPage: 0,
      city: "",
      district: "",
      tableData: [],
      pcaTextArr,
      selectedOptions: [],
      dialogSelectedOptions: [],
      dialogFormVisible: false,
      isEdit: false, //添加和编辑 默认添加
      form: {
        title: "",
        intro: "",
        coverImage: "",
        content: "",
        status: "",
        id: "",
        type: 1 || 2,
      },
      // formLabelWidth: '120px'
    };
  },
  mounted() {
    this.listRequest();
  },
  methods: {
    // 监控locaStorage
    // watchStorage() {
    //   const that = this;
    //   window.addEventListener("setItemEvent", function (e) {
    //     // 监听setitem的 key ，执行对应的业务逻辑
    //     console.log(e.key, e.value);
    //     if (e.key === "projectID") {
    //       const newProjectId = e.value;
    //       that.listRequest(newProjectId);
    //     }
    //   });
    // },
    // 请求新闻列表接口
    listRequest() {
      var that = this;
      let param = {
        pageNum: that.currentPage,
        pageSize: that.pageSize,
        title: that.formData.title,
        intro: that.formData.intro,
        status: that.formData.status,
        type: that.formData.type,
      };
      this.$post("/manage/new/pageList", param)
        .then((res) => {
          if (res.code == 1) {
            // console.log(res.data.list);
            this.tableData = res.data.list;
            this.totalPage = res.data.total;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          console.log("异常");
        });
    },
    handleAvatarSuccess(res) {
      // 文件上传成功后的处理逻辑
      let that = this;
      this.$message({
        message: "上传成功!",
        type: "success",
        duration: 1000,
      });
      this.$nextTick(() => {
        // @ts-ignore
        that.form.coverImage = res.data;
      });
    },
    // 查询
    onSubmit() {
      this.listRequest();
    },
    // 新增新闻
    addDevice() {
      // @ts-ignore
      this.form = {};
      this.dialogSelectedOptions = [];
      this.dialogFormVisible = true;
      this.isEdit = false;
    },
    //编辑按钮
    handleEdit(index, row) {
      this.dialogFormVisible = true;
      this.isEdit = true;
      this.dialogSelectedOptions = [];
      // @ts-ignore
      this.dialogSelectedOptions.push(row.district);
      this.$nextTick(() => {
        //赋值
        this.form = Object.assign({}, row);
      });
    },
    //删除按钮
    handleDelete(index, row) {
      // console.log(row);
      let param = {
        id: row.id,
      };
      this.$get("/manage/new/del", param)
        .then((response) => {
          if (response.code === "1") {
            this.$message({
              message: "删除新闻成功!",
              type: "success",
              duration: 1000,
            });
            // 刷新整个页面
            this.reload();
          } else {
            this.$message({
              message: response.msg,
              type: "warning",
              duration: 1000,
            });
          }
        })
        .catch((error) => {
          this.$message({
            message: error.msg,
            type: "error",
            duration: 1000,
          });
        });
    },

    //关闭dialog前
    closeExpertFormDialog() {
      this.$nextTick(() => {
        // @ts-ignore
        this.$refs["form"].resetFields();
        this.dialogSelectedOptions = [];
      });
      this.dialogFormVisible = false;
      this.isEdit = false;
    },
    //编辑添加弹框取消按钮
    handleDialogCancel() {
      this.$nextTick(() => {
        // @ts-ignore
        this.$refs["form"].resetFields();
        this.dialogSelectedOptions = [];
      });
      this.dialogFormVisible = false;
      this.isEdit = false;
    },
    //编辑添加弹框确认按钮
    handleDialogConfirm() {
      const {
        title,
        intro,
        coverImage,
        content,
        status,
        type,
        id
      } = this.form;
      if (this.isEdit) {
        // 编辑操作
        let param = {
          title,
          intro,
          coverImage,
          content,
          status,
          type,
          id
        };
        this.$post("/manage/new/saveOrUpdate", param)
          .then((response) => {
            if (response.code === "1") {
              this.$message({
                message: "编辑新闻成功!",
                type: "success",
                duration: 1000,
              });
              // 刷新整个页面
              this.reload();
              this.dialogFormVisible = false;
              this.$refs["form"].resetFields();
              this.dialogSelectedOptions = [];
            } else {
              this.$message({
                message: response.msg,
                type: "warning",
                duration: 1000,
              });
              this.dialogSelectedOptions = [];
            }
          })
          .catch((error) => {
            this.$message({
              message: error.msg,
              type: "error",
              duration: 1000,
            });
          });
      } else {
        let param = {
          title,
          intro,
          coverImage,
          content,
          status,
          type,
        };
        this.$post("/manage/new/saveOrUpdate", param)
          .then((response) => {
            if (response.code === "1") {
              this.$message({
                message: "添加新闻成功!",
                type: "success",
                duration: 1000,
              });
              // 刷新整个页面
              this.reload();
              this.dialogFormVisible = false;
              this.$refs["form"].resetFields();
              this.dialogSelectedOptions = [];
            } else {
              this.$message({
                message: response.msg,
                type: "warning",
                duration: 1000,
              });
              this.dialogSelectedOptions = [];
            }
          })
          .catch((error) => {
            this.$message({
              message: error.msg,
              type: "error",
              duration: 1000,
            });
          });
      }

      this.$nextTick(() => {
        // @ts-ignore
        this.$refs["form"].resetFields();
      });
      this.dialogFormVisible = false;
      this.isEdit = false;
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.listRequest();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.listRequest();
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  margin: 10px;
  .search {
    margin: 20px auto;
    background-color: #fff;
    display: flex;
    align-items: center;
    // padding-top:20px ;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 0 10px;
    box-sizing: border-box;
    .el-form {
      display: flex;
      // justify-content: space-between;
      margin-top: 20px;
      .el-form-item {
        // margin-left: 10px;
        display: flex;
        flex-wrap: wrap;

        /deep/ .el-form-item__content {
          vertical-align: middle !important;
          width: 180px !important;
        }
        /deep/ .el-form-item__label {
          color: rgba(0, 0, 0, 0.85) !important;
        }
      }
    }
  }
  .table {
    margin: 10px 0;
    background-color: #fff;
    padding: 10px;
    .table-header {
      display: flex;
      justify-content: space-between;
      // margin: 20px 10px;
      padding: 10px 0px 0px;
      .table-header-title {
        padding: 10px 0px 0px;
      }
    }
    .el-table {
      margin: 20px auto;
    }
  }
  .avatar-uploader {
    width: 178px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>
